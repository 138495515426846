import { render, staticRenderFns } from "./messages.vue?vue&type=template&id=0b2edaae&scoped=true&"
import script from "./messages.vue?vue&type=script&lang=js&"
export * from "./messages.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b2edaae",
  null
  
)

export default component.exports
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QItem,QItemSection,QAvatar,QItemLabel});qInstall(component, 'directives', {Ripple});
