<template>
 
    <q-header elevated>
      <q-toolbar class="bg-blue-grey-9">
        <q-btn
          flat
          dense
          round
          @click="leftDrawerChange"
          icon="menu"
          aria-label="Menu"
        />

        <q-toolbar-title>
          <router-link style="text-decoration:none; color:white" to="/">
            Верни моё
          </router-link>
        </q-toolbar-title>


        <q-space/>

        <div class="q-gutter-sm row items-center no-wrap">
          <!-- <q-btn round dense flat color="white" :icon="$q.fullscreen.isActive ? 'fullscreen_exit' : 'fullscreen'"
                 @click="$q.fullscreen.toggle()"
                 v-if="$q.screen.gt.sm"> -->
            <!--            <q-tooltip>Messages</q-tooltip>-->
          <!-- </q-btn> -->
          <q-btn round dense flat color="white" icon="notifications">
            <q-badge v-if="msgCount > 0" color="red" text-color="white" floating>
              {{msgCount}}
            </q-badge>
            <q-menu v-if="msgCount > 0">
              <q-list style="min-width: 100px">
                <messages></messages>
                <q-card class="text-center no-shadow no-border">
                  <q-btn label="View All" style="max-width: 120px !important;" flat dense
                         class="text-indigo-8"></q-btn>
                </q-card>
              </q-list>
            </q-menu>
             <q-tooltip>События</q-tooltip>
          </q-btn>
          <q-btn round flat color="white" icon="person_pin" > 
            <q-tooltip>Пользователь</q-tooltip>
             <q-menu>
              <q-list style="min-width: 200px">
                 <q-item style="max-width: 420px" clickable v-ripple>
                    <q-item-section avatar>
                      <q-avatar icon="perm_identity" color="white" text-color="primary" />
                    </q-item-section>
                    <q-item-section side>
                      Профиль
                    </q-item-section>
                  </q-item>
                 <q-item style="max-width: 420px" clickable v-ripple @click="logOut">
                    <q-item-section avatar>
                      <q-avatar icon="logout" color="white" text-color="primary" />
                    </q-item-section>
                    <q-item-section side>
                      Выйти
                    </q-item-section>
                  </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </q-toolbar>
    </q-header>
  
</template>

<script>
    import Messages from "./messages";
    export default {
        name: "TopHeader",
        components: {
            Messages,
        },
        data() {
            return {
               msgCount: 0
            }
        },
        methods: {
          leftDrawerChange(){
            this.$store.commit('LEFT_DROWER_CHANGE');
          },
          logOut(){
            this.$store.commit('REMOVE_TOKEN');
            this.$router.push('/login')
          }
        }
    }
</script>

<style scoped>
</style>