<template>
  <q-layout view="hHh lpr lFr">
   
    <main-header/>
    
    <left-menu/>

    <q-page-container class="bg-grey-2">
      <div v-if="bcList.length > 1" class="q-pa-md q-gutter-sm shadow-3">
      <q-breadcrumbs >
        <q-breadcrumbs-el v-for="(bc, index) in bcList" :key="index" :label="bc.name" :to="bc.link" />
      </q-breadcrumbs>
    </div>
      <router-view/>
    </q-page-container>
  </q-layout>
</template>

<script>
import LeftMenu from './left-menu.vue';

    // import EssentialLink from 'components/EssentialLink'
    import MainHeader from "./top-header";
    export default {
        name: 'MainLayout',
        components: {
                MainHeader,
                LeftMenu
        },
        data() {
            return {
                bcList : this.$route.meta.bc
            }
        },
        watch:{
          '$route'() {
            this.bcList = this.$route.meta.bc
          }
        }
    }
</script>