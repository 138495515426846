<template>
  
    <q-drawer
      v-model="$store.state.leftDrawerOpen"
      show-if-above
      bordered
      content-class="bg-grey-3 text-grey-9"
    >
      <q-list>

        <template v-for="(menuItem, index) in menuList">
            <q-item 
                v-if="!menuItem.sublist" 
                :key="index" clickable 
                :active="menuItem.link === $route.path" 
                v-ripple 
                active-class="bg-blue-grey-7 text-grey-1"
                :to="menuItem.link">
                
                <q-item-section avatar>
                    <q-icon :name="menuItem.icon" />
                </q-item-section>
                <q-item-section>
                    {{ menuItem.label }}
                </q-item-section>
            </q-item>
            <q-separator v-if="menuItem.label === 'Настройки'" :key="`s-${index}`"   />
            <q-expansion-item v-if="menuItem.sublist" :key="index" :icon="menuItem.icon" :label="menuItem.label" :default-opened="subOpen(index)">
                <q-list class="q-pl-lg">
                    <template v-for="(menuSubItem, subIndex) in menuItem.sublist">
                        <q-item :key="subIndex" :to="menuSubItem.link" :active="menuSubItem.link === $route.path" active-class="bg-blue-grey-7 text-grey-1 ">
                            <q-item-section avatar>
                                <q-icon :name="menuSubItem.icon"/>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>{{ menuSubItem.label }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        
                    </template>
                </q-list>
            </q-expansion-item>
        </template>
      </q-list>
    </q-drawer>
  
</template>

<script>
    export default {
        name: "LeftMenu",
        data() {
            return {
                menuList: [
                    {
                        icon: 'work',
                        label: 'Дела',
                        link: '/cases',
                        sublist: false
                    },
                    {
                        icon: 'assignment',
                        label: 'Нарушения',
                        link: '/violations',
                        sublist: false
                    },
                   
                     {
                        icon: 'supervisor_account',
                        label: 'Авторы',
                        link: '/authors',
                        sublist: false
                    },
                    {
                        icon: 'policy',
                        label: 'Правообладатели',
                        link: '/copyright-holders',
                        sublist: false
                    },
                     {
                        icon: 'supervised_user_circle',
                        label: 'Нарушители',
                        link: '/violators',
                        sublist: false
                    },
                    {
                        icon: 'integration_instructions',
                        label: 'Шаблоны',
                        sublist: [
                            {
                                icon: 'list',
                                label: 'Маркеры',
                                sublist: false,
                                link:'/tpl-attributes'
                            },
                            {
                                icon: 'list',
                                label: 'Блоки',
                                sublist: false,
                                link:'/tpl-blocks'
                            },
                            {
                                icon: 'list',
                                label: 'Документы',
                                sublist: false,
                                link:'/tpl-documents'
                            },
                            // {
                            //     icon: 'list',
                            //     label: 'Уведомления',
                            //     sublist: false,
                            //     link:'/tpl-notify'
                            // },
                        ]
                        
                    },
                    {
                        icon: 'list_alt',
                        label: 'Справочники',
                        sublist: [
                            {
                                icon: 'list',
                                label: 'Регистраторы доменов',
                                sublist: false,
                                link:'/dir-domain-regs'
                            },
                            // {
                            //     icon: 'list',
                            //     label: 'Типы проверок',
                            //     sublist: false,
                            //     link:'/dir-check-types'
                            // },
                            {
                                icon: 'list',
                                label: 'Статусы',
                                sublist: false,
                                link:'/dir-stats'
                            },
                            {
                                icon: 'list',
                                label: 'Структура документов',
                                sublist: false,
                                link:'/dir-documents'
                            },
                        ]
                        
                    },
                    {
                        icon: 'miscellaneous_services',
                        label: 'Настройки',
                        sublist: [
                            {
                                icon: 'supervisor_account',
                                label: 'Пользователи',
                                sublist: false,
                                link:'/config-users'
                            },
                            {
                                icon: 'settings',
                                label: 'Параметры',
                                sublist: false,
                                link:'/config-settings'
                            },
                        ]
                        
                    },
                   
                ]
            }
        },
        methods:{
            subOpen(index){
                if(this.menuList[index].sublist !== false){
                    if(this.menuList[index].sublist.filter(val => val.link === this.$route.path).length > 0) return true
                }
                return false
            }
        }
    }
</script>

<style scoped>

.active-border {
    border-left: 3px solid red;
}
</style>